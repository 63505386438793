import APIUtility from "../js/utility-api-bin-management";
import EncryptUtility from "./encrypt-utility.js";
import Utility from "../../../shared/utility";
import AddFavourite from "./addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
export default {
  data() {
    return {
      tab: "",
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      binValid: false,
      rowDesignator: "",
      kanban: false,
      numShelvesVertical: "",
      numBinsHorizontal: "",
      rowDesignatorSearch: "",
      binStatus: "",
      binStatusItems: [],
      selectedProjSecSearch: "",
      projItemsSecSearch: [],
      selectedBusSecSearch: "",
      busiItemsSecSearch: [],
      selectedWareSecSearch: "",
      warehouseItemsSecSearch: [],
      locationSecSearch: "",
      locationItemsSecSearch: [],
      OEMSecSearch: "",
      OEMItemsSecSearch: [],
      partTypeSecSearch: "",
      partTypeItemsSecSearch: [],
      partClassSecSearch: "",
      partClassItemsSecSearch: [],
      binTypeSecSearch: "",
      binTypeItemsSecSearch: [],
      statusSecSearch: "",
      statusItemsSecSearch: [],
      binRange1: "",
      binRange2: "",
      addRowFormValid: false,
      firstSearchValid: false,
      secondSearchValid: false,
      selectedProject: true,
      selectedBusiness: true,
      selectedWarehouse: true,
      selectedLocation: true,
      selectedOEM: true,
      selectedPartType: true,
      selectedPartClass: true,
      selectedBinType: true,
      selectedStatus: true,
      binDetails: false,
      projectThirdSearch: "",
      projectItemsThirdSearch: [],
      rowThirdSearch: "",
      rowItemsThirdSearch: [],
      searchBtnClickEvt: true,
      searchProjectSelected: true,
      binsSetFixedPart: false,
      discrepancyBins: false,
      undefinedBins: false,
      stagingSkids: false,
      kanbanCarts: false,
      projectVal: "",
      stagingSkidsEnable: true,
      statusConfigEnable: true,
      kanbanControlEnable: false,
      binsList: [],
      binInventoryBinsValid: false,
      projectInvenBins: "",
      projectItemsInvenBins: [],
      partNumberInventBins: "",
      businessInventBins: "",
      businessItemsInventBins: [],
      warehouseInventBins: "",
      warehouseItemsInventBins: [],
      locationInventBins: "",
      locationItemsInventBins: [],
      OEMInventBins: "",
      OEMItemsInventBins: [],
      partTypeInventBins: "",
      partTypeItemsInventBins: [],
      partClassInventBins: "",
      partClassItemsInventBins: [],
      binTypeInventBins: "",
      binTypeItemsInventBins: [],
      selectedBinStatus: 0,
      isActiveStagingSkids: false,
      statusItemsBins: [],
      statusBins: "",
      specificDialog: false,
      partClassSearchValid: false,
      firstSearch: "",
      firstSearchItems: [],
      secondSearch: "",
      secondSearchItems: [],
      txtPartNumber: "",
      searchPartNo: "",
      searchPartSearch: "",
      totalRecords: 0,
      partDetailsSearch: [],
      partDetailsShow: false,
      dynamicSelected: true,
      searchFirstItem: false,
      searchSecondItem: false,
      projInvenBinsSelec: true,
      wareInvenBinsSelec: true,
      locInvenBinsSelec: true,
      OEMInvenBinsSelec: true,
      partTypeInvenBinsSelec: true,
      partClassInvenBinsSelec: true,
      binTypeInvenBinsSelec: true,
      busiInvenBinsSelec: true,
      binString: "",
      rowString: "",
      configureClick: true,
      applyEvent: true,
      projectClick: true,
      searchFixedPart: false,
      searchUndefinedBin: false,
      searchStagingSkids: false,
      searchDiscrepancyBin: false,
      searchKanbanBin: false,
      deleteList: [],
      singleSelect: false,
      max10Rule: [(v) => (v || "").length <= 10 || "Max Length of 10 character"],
      max5Rule: [(v) => (v || "").length <= 5 || "Max Length of 5 character"],
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character"],
      searchdeleterow: "",
      showPrimary: true,
      deleteHeaders: [
        {
          text: "Row",
          align: "start",
          value: "Row",
        },
        {
          text: "Created",
          align: "start",
          value: "Created",
        },
      ],
      headersBins: [
        {
          text: "Active",
          align: "start",
          value: "Active",
        },
        {
          text: "Bin",
          value: "Bin",
        },
        {
          text: "Project",
          value: "Project",
        },
        {
          text: "Business",
          value: "Business",
        },
        {
          text: "Sub-Business",
          value: "Sub-Business",
        },
        {
          text: "Ware",
          value: "Ware",
        },
        {
          text: "Location",
          value: "Location",
        },
        {
          text: "Part Type",
          value: "Part Type",
        },
        {
          text: "OEM",
          value: "OEM",
        },
        {
          text: "Class",
          value: "Class",
        },
        {
          text: "Part#",
          value: "Part#",
        },
        {
          text: "Bin Type",
          value: "Bin Type",
        },
        {
          text: "Qty",
          value: "Qty",
        },
        {
          text: "KB!",
          value: "KB!",
        },
      ],
      headersPartSearchTbl: [
        {
          text: "Part No",
          align: "start",
          value: "PartNo",
          class: "primary customwhite--text",
        },
        {
          text: "Project",
          value: "Project",
          class: "primary customwhite--text",
        },
        {
          text: "Description",
          value: "Description",
          class: "primary customwhite--text",
        },
        {
          text: "Model",
          value: "Model",
          class: "primary customwhite--text",
        },
        {
          text: "Part Type",
          value: "Part Type",
          class: "primary customwhite--text",
        },
        {
          text: "OEM Model",
          value: "OEM Model",
          class: "primary customwhite--text",
        },
        {
          text: "OEM Part",
          value: "OEM Part",
          class: "primary customwhite--text",
        },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.dropdownValuesSecondSearch();
    this.binStatusDataList();
    this.DeleteRowSelected();
  },
  methods: {
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event)
      return value
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.binValid = false;
      this.rowDesignator = "";
      this.kanban = false;
      this.numShelvesVertical = "";
      this.numBinsHorizontal = "";
      this.rowDesignatorSearch = "";
      this.binStatus = "";
      this.binStatusItems = [];
      this.selectedProjSecSearch = "";
      this.projItemsSecSearch = [];
      this.selectedBusSecSearch = "";
      this.busiItemsSecSearch = [];
      this.selectedWareSecSearch = "";
      this.warehouseItemsSecSearch = [];
      this.locationSecSearch = "";
      this.locationItemsSecSearch = [];
      this.OEMSecSearch = "";
      this.OEMItemsSecSearch = [];
      this.partTypeSecSearch = "";
      this.partTypeItemsSecSearch = [];
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.binRange1 = "";
      this.binRange2 = "";
      this.addRowFormValid = false;
      this.firstSearchValid = false;
      this.secondSearchValid = false;
      this.selectedProject = true;
      this.selectedBusiness = true;
      this.selectedWarehouse = true;
      this.selectedLocation = true;
      this.selectedOEM = true;
      this.selectedPartType = true;
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.selectedStatus = true;
      this.binDetails = false;
      this.projectThirdSearch = "";
      this.projectItemsThirdSearch = [];
      this.searchBtnClickEvt = true;
      this.rowThirdSearch = "";
      this.rowItemsThirdSearch = [];
      this.searchProjectSelected = true;
      this.binsSetFixedPart = false;
      this.discrepancyBins = false;
      this.undefinedBins = false;
      this.stagingSkids = false;
      this.kanbanCarts = false;
      this.projectVal = "";
      this.stagingSkidsEnable = true;
      this.statusConfigEnable = true;
      this.kanbanControlEnable = false;
      this.binsList = [];
      this.binInventoryBinsValid = false;
      this.projectInvenBins = "";
      this.projectItemsInvenBins = [];
      this.partNumberInventBins = "";
      this.businessInventBins = "";
      this.warehouseInventBins = "";
      this.businessItemsInventBins = [];
      this.warehouseItemsInventBins = [];
      this.locationInventBins = "";
      this.locationItemsInventBins = [];
      this.OEMInventBins = "";
      this.OEMItemsInventBins = [];
      this.partTypeInventBins = "";
      this.partTypeItemsInventBins = [];
      this.partClassInventBins = "";
      this.partClassItemsInventBins = [];
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];
      this.selectedBinStatus = 0;
      this.isActiveStagingSkids = false;
      this.statusItemsBins = [];
      this.statusBins = "";
      this.specificDialog = false;
      this.partClassSearchValid = false;
      this.firstSearch = "";
      this.firstSearchItems = [];
      this.secondSearch = "";
      this.secondSearchItems = [];
      this.txtPartNumber = "";
      this.searchPartNo = "";
      this.searchPartSearch = "";
      this.totalRecords = 0;
      this.partDetailsSearch = [];
      this.partDetailsShow = false;
      this.dynamicSelected = true;
      this.searchFirstItem = false;
      this.searchSecondItem = false;
      this.projInvenBinsSelec = true;
      this.configureClick = true;
      this.wareInvenBinsSelec = true;
      this.locInvenBinsSelec = true;
      this.OEMInvenBinsSelec = true;
      this.partTypeInvenBinsSelec = true;
      this.partClassInvenBinsSelec = true;
      this.binTypeInvenBinsSelec = true;
      this.busiInvenBinsSelec = true;
      this.binString = "";
      this.dropdownValuesSecondSearch();
      this.binStatusDataList();
      if (this.tab == 0) this.$refs.addRowForm.resetValidation();
      if (this.tab == 1) {
        this.$refs.firstSearchForm[0].resetValidation();
        this.$refs.secondSearchForm[0].resetValidation();
      }
      if (this.binDetails) this.$refs.binInventoryBinsForm[0].resetValidation();
    },
    //Add Row Submit Click Event
    async onRowSubmitClick() {
      if (this.$refs.addRowForm[0].validate()) {
        let obj = {
          UserId: parseInt(this.userId),
          cRowPrefix: this.rowDesignator,
          kanban: this.kanban,
          nCols: parseInt(this.numShelvesVertical),
          nRows: parseInt(this.numBinsHorizontal),
          nWare_key: 0,
          nCF_rating: 0,
          nBinType_key: 0,
          cBin_Model: "",
          cBucket: "",
          nbins: 0,
        };
        let showMessage = true;
        let response = await APIUtility.rowAddSubmitCall(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.rowDesignator = "";
          this.numShelvesVertical = "";
          this.numBinsHorizontal = "";
          this.kanban = false;
          this.$refs.addRowForm.resetValidation();
        }
      }
    },
    //First Search Click event
    async onFirstSearchClick() {
      if (this.binStatus == "All") {
        this.selectedBinStatus = 1;
      } else if (this.binStatus == "Active") {
        this.selectedBinStatus = 2;
      } else if (this.binStatus == "Active") {
        this.selectedBinStatus = 3;
      }
      if (this.$refs.firstSearchForm[0].validate()) {
        let obj = {
          search_by_row: 0,
          row: this.rowDesignatorSearch,
          bin1: this.binRange1,
          bin2: this.binRange2,
          bin_status: parseInt(this.selectedBinStatus),
          proj_key: 0,
          bu_key: 0,
          sbu_key: 0,
          ware_key: 0,
          loc: "",
          oem_key: 0,
          bin_model: "",
          class_key: 0,
          btype_key: 0,
          status: "",
          search_for_fixed_parts: false,
          search_for_undefined_bins: false,
          search_for_staging_skids: false,
          search_for_discrep_bins: false,
          search_for_kanban_bins: false,
        };
        let showMessage = true;
        let response = await APIUtility.searchCall(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.binDetails = true;
          this.binsList = response.Result;
          this.kanbanControlEnable = response.Result2[0].kanban_control_bit;
          this.stagingSkidsEnable = !response.Result2[0].enable_staging_skids_bit;
          this.searchFirstItem = false;
          this.searchSecondItem = false;
          this.statusConfigEnable = true;
          this.selectedBusSecSearch = "";
          this.busiItemsSecSearch = [];
          this.selectedWareSecSearch = "";
          this.warehouseItemsSecSearch = [];
          this.locationSecSearch = "";
          this.locationItemsSecSearch = [];
          this.OEMSecSearch = "";
          this.OEMItemsSecSearch = [];
          this.partTypeSecSearch = "";
          this.partTypeItemsSecSearch = [];
          this.partClassSecSearch = "";
          this.partClassItemsSecSearch = [];
          this.binTypeSecSearch = "";
          this.binTypeItemsSecSearch = [];
          this.statusSecSearch = "";
          this.statusItemsSecSearch = [];
          this.selectedProjSecSearch = "";
          this.projItemsSecSearch = [];
          this.searchBtnClickEvt = true;
          this.rowThirdSearch = "";
          this.rowItemsThirdSearch = [];
          this.searchProjectSelected = true;
          this.projectThirdSearch = "";
          this.projectItemsThirdSearch = [];
          this.dropdownValuesSecondSearch();
        }
      }
    },
    //Dropdown values for Second Search
    async dropdownValuesSecondSearch() {
      let response = await APIUtility.drpDownValSecSearch();
      if (response !== null && response !== undefined) {
        this.projItemsSecSearch = response;
      }
    },
    //Data for Bin Status dropdown
    async binStatusDataList() {
      let response = await APIUtility.binStatusData();
      if (response !== null && response !== undefined) {
        let data = response[0]?.status;
        this.binStatusItems = data?.split(",");
      }
    },
    //On Project selection
    projectSelected() {
      this.binDetails = false;
      this.selectedBusSecSearch = "";
      this.busiItemsSecSearch = [];
      this.selectedWareSecSearch = "";
      this.warehouseItemsSecSearch = [];
      this.locationSecSearch = "";
      this.locationItemsSecSearch = [];
      this.OEMSecSearch = "";
      this.OEMItemsSecSearch = [];
      this.partTypeSecSearch = "";
      this.partTypeItemsSecSearch = [];
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedBusiness = true;
      this.selectedWarehouse = true;
      this.selectedProject = true;
      this.selectedLocation = true;
      this.selectedOEM = true;
      this.selectedPartType = true;
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.rowDesignatorSearch = "";
      this.binRange1 = "";
      this.binRange2 = "";
      this.binStatus = "";
      this.binStatusItems = [];
      this.binStatusDataList();
      this.busiItemsSecSearch = this.projItemsSecSearch?.filter((obj) => obj.proj_key === this.selectedProjSecSearch);
      this.selectedProject = false;
      this.searchSecondItem = false;
    },
    //On Business Selection
    businessSelected() {
      this.selectedWareSecSearch = "";
      this.warehouseItemsSecSearch = [];
      this.locationSecSearch = "";
      this.locationItemsSecSearch = [];
      this.OEMSecSearch = "";
      this.OEMItemsSecSearch = [];
      this.partTypeSecSearch = "";
      this.partTypeItemsSecSearch = [];
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedWarehouse = true;
      this.selectedLocation = true;
      this.selectedOEM = true;
      this.selectedPartType = true;
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.warehouseItemsSecSearch = this.busiItemsSecSearch?.filter((obj) => obj.bu_key === this.selectedBusSecSearch);
      this.selectedBusiness = false;
      this.selectedProject = false;
    },
    //On Warehouse Selection
    warehouseSelected() {
      this.locationSecSearch = "";
      this.locationItemsSecSearch = [];
      this.OEMSecSearch = "";
      this.OEMItemsSecSearch = [];
      this.partTypeSecSearch = "";
      this.partTypeItemsSecSearch = [];
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedLocation = true;
      this.selectedOEM = true;
      this.selectedPartType = true;
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.locationItemsSecSearch = this.warehouseItemsSecSearch?.filter((obj) => obj.ware_key === this.selectedWareSecSearch);
      this.selectedWarehouse = false;
      this.selectedBusiness = false;
    },
    //On Location Selection
    locationSelected() {
      this.OEMSecSearch = "";
      this.OEMItemsSecSearch = [];
      this.partTypeSecSearch = "";
      this.partTypeItemsSecSearch = [];
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedOEM = true;
      this.selectedPartType = true;
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.OEMItemsSecSearch = this.locationItemsSecSearch?.filter((obj) => obj.location === this.locationSecSearch);
      this.selectedLocation = false;
      this.selectedWarehouse = false;
    },
    //On OEM Selection
    OEMSelected() {
      this.partTypeSecSearch = "";
      this.partTypeItemsSecSearch = [];
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedPartType = true;
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.partTypeItemsSecSearch = this.OEMItemsSecSearch?.filter((obj) => obj.oem_key === this.OEMSecSearch);
      this.selectedOEM = false;
      this.selectedLocation = false;
    },
    //On Part Type Selection
    partTypeSelected() {
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.partClassItemsSecSearch = this.partTypeItemsSecSearch?.filter((obj) => obj.ptype === this.partTypeSecSearch);
      this.selectedPartType = false;
      this.selectedOEM = false;
    },
    //On Part Type Selection
    partClassSelected() {
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedBinType = true;
      this.binTypeItemsSecSearch = this.partClassItemsSecSearch?.filter((obj) => obj.class_key === this.partClassSecSearch);
      this.selectedPartClass = false;
      this.selectedPartType = false;
    },
    //On Bin Type Selection
    binTypeSelected() {
      this.statusItemsSecSearch = this.partClassItemsSecSearch?.filter((obj) => obj.btype_key === this.binTypeSecSearch);
      this.selectedBinType = false;
      this.selectedPartClass = false;
    },
    //On Status Selection
    statusSelected() {
      this.selectedStatus = false;
      this.selectedBinType = false;
    },
    //Second Search Click event
    async onSecondSearchClick() {
      if (this.$refs.secondSearchForm[0].validate()) {
        let obj = {
          search_by_row: 0,
          row: "",
          bin1: "",
          bin2: "",
          bin_status: 0,
          proj_key: parseInt(this.selectedProjSecSearch),
          bu_key: parseInt(this.selectedBusSecSearch),
          sbu_key: 0,
          ware_key: parseInt(this.selectedWareSecSearch),
          loc: this.locationSecSearch,
          oem_key: parseInt(this.OEMSecSearch),
          bin_model: this.statusItemsSecSearch[0].bin_model,
          class_key: parseInt(this.partClassSecSearch),
          btype_key: parseInt(this.binTypeSecSearch),
          status: this.statusSecSearch,
          search_for_fixed_parts: false,
          search_for_undefined_bins: false,
          search_for_staging_skids: false,
          search_for_discrep_bins: false,
          search_for_kanban_bins: false,
        };
        let showMessage = true;
        let response = await APIUtility.searchCall(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.binDetails = true;
          this.binsList = response?.Result;
          this.kanbanControlEnable = response?.Result2[0]?.kanban_control_bit;
          this.stagingSkidsEnable = !response?.Result2[0]?.enable_staging_skids_bit;
          this.selectedStatus = false;
          this.statusConfigEnable = true;
          this.rowDesignatorSearch = "";
          this.binRange1 = "";
          this.binRange2 = "";
          this.binStatus = "";
          this.binStatusItems = [];
          this.binStatusDataList();
          this.rowThirdSearch = "";
          this.rowItemsThirdSearch = [];
          this.searchProjectSelected = true;
          this.projectThirdSearch = "";
          this.projectItemsThirdSearch = [];
        }
      }
    },
    //Search button click events for Search For Bins Set To Fixed Part #
    async searchBtnBinsSetFixedPart() {
      this.pass = 1;
      this.projectItemsThirdSearch = [];
      this.rowItemsThirdSearch = [];
      this.rowThirdSearch = "";
      this.projectThirdSearch = "";
      let response = await APIUtility.searchForBtnClick(this.pass, this.userId);
      if (response !== null && response !== undefined) {
        this.projectItemsThirdSearch = response;
        this.searchBtnClickEvt = false;
        this.searchProjectSelected = true;
        this.binsSetFixedPart = false;
        this.discrepancyBins = false;
        this.undefinedBins = false;
        this.stagingSkids = false;
        this.kanbanCarts = false;
        this.searchFirstItem = false;
        this.searchSecondItem = false;
        this.rowDesignatorSearch = "";
        this.binRange1 = "";
        this.binRange2 = "";
        this.binStatus = "";
        this.binStatusItems = [];
        this.binStatusDataList();
        this.binDetails = false;
        this.selectedBusSecSearch = "";
        this.busiItemsSecSearch = [];
        this.selectedWareSecSearch = "";
        this.warehouseItemsSecSearch = [];
        this.locationSecSearch = "";
        this.locationItemsSecSearch = [];
        this.OEMSecSearch = "";
        this.OEMItemsSecSearch = [];
        this.partTypeSecSearch = "";
        this.partTypeItemsSecSearch = [];
        this.partClassSecSearch = "";
        this.partClassItemsSecSearch = [];
        this.binTypeSecSearch = "";
        this.binTypeItemsSecSearch = [];
        this.statusSecSearch = "";
        this.statusItemsSecSearch = [];
        this.selectedProjSecSearch = "";
        this.projItemsSecSearch = [];
        this.dropdownValuesSecondSearch();
        this.selectedBusiness = true;
        this.selectedWarehouse = true;
        this.selectedProject = true;
        this.selectedLocation = true;
        this.selectedOEM = true;
        this.selectedPartType = true;
        this.selectedPartClass = true;
        this.selectedBinType = true;
        this.searchFixedPart = true;

        this.searchUndefinedBin = false;
        this.searchStagingSkids = false;
        this.searchDiscrepancyBin = false;
        this.searchKanbanBin = false;
      }
    },
    //Search button click events for Search For Discrepancy Bins
    async searchBtnDiscrepancyBins() {
      this.pass = 2;
      this.projectItemsThirdSearch = [];
      this.rowItemsThirdSearch = [];
      this.rowThirdSearch = "";
      this.projectThirdSearch = "";
      let response = await APIUtility.searchForBtnClick(this.pass, this.userId);
      if (response !== null && response !== undefined) {
        this.projectItemsThirdSearch = response;
        this.searchBtnClickEvt = false;
        this.searchProjectSelected = true;
        this.binsSetFixedPart = false;
        this.discrepancyBins = false;
        this.undefinedBins = false;
        this.stagingSkids = false;
        this.kanbanCarts = false;
        this.searchFirstItem = false;
        this.searchSecondItem = false;
        this.rowDesignatorSearch = "";
        this.binRange1 = "";
        this.binRange2 = "";
        this.binStatus = "";
        this.binStatusItems = [];
        this.binStatusDataList();
        this.binDetails = false;
        this.selectedBusSecSearch = "";
        this.busiItemsSecSearch = [];
        this.selectedWareSecSearch = "";
        this.warehouseItemsSecSearch = [];
        this.locationSecSearch = "";
        this.locationItemsSecSearch = [];
        this.OEMSecSearch = "";
        this.OEMItemsSecSearch = [];
        this.partTypeSecSearch = "";
        this.partTypeItemsSecSearch = [];
        this.partClassSecSearch = "";
        this.partClassItemsSecSearch = [];
        this.binTypeSecSearch = "";
        this.binTypeItemsSecSearch = [];
        this.statusSecSearch = "";
        this.statusItemsSecSearch = [];
        this.selectedProjSecSearch = "";
        this.projItemsSecSearch = [];
        this.dropdownValuesSecondSearch();
        this.selectedBusiness = true;
        this.selectedWarehouse = true;
        this.selectedProject = true;
        this.selectedLocation = true;
        this.selectedOEM = true;
        this.selectedPartType = true;
        this.selectedPartClass = true;
        this.selectedBinType = true;
        this.searchDiscrepancyBin = true;
        this.searchUndefinedBin = false;
        this.searchStagingSkids = false;
        this.searchKanbanBin = false;
        this.searchFixedPart = false;
      }
    },
    //Search button click events for Search For Undefined Bins
    async searchBtnUndefinedBins() {
      this.pass = 4;
      this.rowItemsThirdSearch = [];
      this.projectItemsThirdSearch = [];
      this.rowThirdSearch = "";
      this.projectThirdSearch = "";
      let response = await APIUtility.searchForBtnClick(this.pass, this.userId);
      if (response !== null && response !== undefined) {
        this.rowItemsThirdSearch = response;
        this.searchProjectSelected = false;
        this.searchBtnClickEvt = true;
        this.binsSetFixedPart = false;
        this.discrepancyBins = false;
        this.undefinedBins = false;
        this.stagingSkids = false;
        this.kanbanCarts = false;
        this.searchFirstItem = false;
        this.searchSecondItem = false;
        this.rowDesignatorSearch = "";
        this.binRange1 = "";
        this.binRange2 = "";
        this.binStatus = "";
        this.binStatusItems = [];
        this.binStatusDataList();
        this.binDetails = false;
        this.selectedBusSecSearch = "";
        this.busiItemsSecSearch = [];
        this.selectedWareSecSearch = "";
        this.warehouseItemsSecSearch = [];
        this.locationSecSearch = "";
        this.locationItemsSecSearch = [];
        this.OEMSecSearch = "";
        this.OEMItemsSecSearch = [];
        this.partTypeSecSearch = "";
        this.partTypeItemsSecSearch = [];
        this.partClassSecSearch = "";
        this.partClassItemsSecSearch = [];
        this.binTypeSecSearch = "";
        this.binTypeItemsSecSearch = [];
        this.statusSecSearch = "";
        this.statusItemsSecSearch = [];
        this.selectedProjSecSearch = "";
        this.projItemsSecSearch = [];
        this.dropdownValuesSecondSearch();
        this.selectedBusiness = true;
        this.selectedWarehouse = true;
        this.selectedProject = true;
        this.selectedLocation = true;
        this.selectedOEM = true;
        this.selectedPartType = true;
        this.selectedPartClass = true;
        this.selectedBinType = true;
        this.searchUndefinedBin = true;
        this.searchStagingSkids = false;
        this.searchDiscrepancyBin = false;
        this.searchKanbanBin = false;
        this.searchFixedPart = false;
      }
    },
    //Search button click events for Search For Staging Skids
    async searchBtnStagingSkids() {
      this.pass = 5;
      this.rowItemsThirdSearch = [];
      this.projectItemsThirdSearch = [];
      this.rowThirdSearch = "";
      this.projectThirdSearch = "";
      let response = await APIUtility.searchForBtnClick(this.pass, this.userId);
      if (response !== null && response !== undefined) {
        this.rowItemsThirdSearch = response;
        this.searchProjectSelected = false;
        this.searchBtnClickEvt = true;
        this.binsSetFixedPart = false;
        this.discrepancyBins = false;
        this.undefinedBins = false;
        this.stagingSkids = false;
        this.kanbanCarts = false;
        this.searchFirstItem = false;
        this.searchSecondItem = false;
        this.rowDesignatorSearch = "";
        this.binRange1 = "";
        this.binRange2 = "";
        this.binStatus = "";
        this.binStatusItems = [];
        this.binStatusDataList();
        this.binDetails = false;
        this.selectedBusSecSearch = "";
        this.busiItemsSecSearch = [];
        this.selectedWareSecSearch = "";
        this.warehouseItemsSecSearch = [];
        this.locationSecSearch = "";
        this.locationItemsSecSearch = [];
        this.OEMSecSearch = "";
        this.OEMItemsSecSearch = [];
        this.partTypeSecSearch = "";
        this.partTypeItemsSecSearch = [];
        this.partClassSecSearch = "";
        this.partClassItemsSecSearch = [];
        this.binTypeSecSearch = "";
        this.binTypeItemsSecSearch = [];
        this.statusSecSearch = "";
        this.statusItemsSecSearch = [];
        this.selectedProjSecSearch = "";
        this.projItemsSecSearch = [];
        this.dropdownValuesSecondSearch();
        this.selectedBusiness = true;
        this.selectedWarehouse = true;
        this.selectedProject = true;
        this.selectedLocation = true;
        this.selectedOEM = true;
        this.selectedPartType = true;
        this.selectedPartClass = true;
        this.selectedBinType = true;
        this.searchStagingSkids = true;
        this.searchUndefinedBin = false;
        this.searchDiscrepancyBin = false;
        this.searchKanbanBin = false;
        this.searchFixedPart = false;
      }
    },
    //Search button click events for Search For Kanban Carts
    async searchBtnKanbanCarts() {
      this.pass = 3;
      this.projectItemsThirdSearch = [];
      this.rowItemsThirdSearch = [];
      this.rowThirdSearch = "";
      this.projectThirdSearch = "";
      let response = await APIUtility.searchForBtnClick(this.pass, this.userId);
      if (response !== null && response !== undefined) {
        this.projectItemsThirdSearch = response;
        this.searchBtnClickEvt = false;
        this.searchProjectSelected = true;
        this.binsSetFixedPart = false;
        this.discrepancyBins = false;
        this.undefinedBins = false;
        this.stagingSkids = false;
        this.kanbanCarts = false;
        this.searchFirstItem = false;
        this.searchSecondItem = false;
        this.rowDesignatorSearch = "";
        this.binRange1 = "";
        this.binRange2 = "";
        this.binStatus = "";
        this.binStatusItems = [];
        this.binStatusDataList();
        this.selectedBusSecSearch = "";
        this.busiItemsSecSearch = [];
        this.selectedWareSecSearch = "";
        this.warehouseItemsSecSearch = [];
        this.locationSecSearch = "";
        this.locationItemsSecSearch = [];
        this.OEMSecSearch = "";
        this.OEMItemsSecSearch = [];
        this.partTypeSecSearch = "";
        this.partTypeItemsSecSearch = [];
        this.partClassSecSearch = "";
        this.partClassItemsSecSearch = [];
        this.binTypeSecSearch = "";
        this.binTypeItemsSecSearch = [];
        this.statusSecSearch = "";
        this.statusItemsSecSearch = [];
        this.selectedProjSecSearch = "";
        this.projItemsSecSearch = [];
        this.dropdownValuesSecondSearch();
        this.selectedBusiness = true;
        this.selectedWarehouse = true;
        this.selectedProject = true;
        this.selectedLocation = true;
        this.selectedOEM = true;
        this.selectedPartType = true;
        this.selectedPartClass = true;
        this.selectedBinType = true;
        this.binDetails = false;
        this.searchKanbanBin = true;
        this.searchUndefinedBin = false;
        this.searchStagingSkids = false;
        this.searchDiscrepancyBin = false;
        this.searchFixedPart = false;
      }
    },
    //Project under third search on selection
    async searchProjSelected() {
      if (this.searchFixedPart == true) {
        this.pass = 11;
      } else if (this.searchDiscrepancyBin == true) {
        this.pass = 22;
      } else if (this.searchKanbanBin == true) {
        this.pass = 33;
      }
      this.rowThirdSearch = "";
      this.rowItemsThirdSearch = [];
      let response = await APIUtility.searchForRowProject(this.pass, this.userId, this.projectThirdSearch);
      if (response !== null && response !== undefined) {
        this.rowItemsThirdSearch = response;
        this.searchProjectSelected = false;
        this.searchBtnClickEvt = false;
      }
    },
    //Project under third search on selection
    async DeleteRowSelected() {
      this.deleteList = [];
      let response = await APIUtility.getDeleteRow();
      if (response !== null && response !== undefined) {
        this.deleteList = response;
      }
    },
    //Row under third search on selection
    async searchRowSelected() {
      if (this.projectThirdSearch == "") {
        this.projectVal = 0;
      } else {
        this.projectVal = this.projectThirdSearch;
      }
      let obj = {
        search_by_row: false,
        row: this.rowThirdSearch,
        bin1: "",
        bin2: "",
        bin_status: 0,
        proj_key: parseInt(this.projectVal),
        bu_key: 0,
        sbu_key: 0,
        ware_key: 0,
        loc: "",
        oem_key: 0,
        bin_model: "",
        class_key: 0,
        btype_key: 0,
        status: "Active",
        search_for_fixed_parts: this.searchFixedPart,
        search_for_undefined_bins: this.searchUndefinedBin,
        search_for_staging_skids: this.searchStagingSkids,
        search_for_discrep_bins: this.searchDiscrepancyBin,
        search_for_kanban_bins: this.searchKanbanBin,
      };
      let showMessage = true;
      let response = await APIUtility.searchCall(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.binDetails = true;
        this.binsList = response.Result;
        this.kanbanControlEnable = response.Result2[0].kanban_control_bit;
        this.stagingSkidsEnable = !response.Result2[0].enable_staging_skids_bit;

        if (this.searchStagingSkids == true) this.searchBtnClickEvt = true;
        this.searchProjectSelected = false;
        this.statusConfigEnable = true;
      }
    },
    //Data table change event
    handleCheckboxChange(item) {
      if (item.length !== 0) {
        this.applyEvent = false;
        this.configureClick = false;
        this.statusConfigEnable = false;
        this.projectDetailsInventoryBins();
      } else {
        this.projectClick = true;
        this.applyEvent = true;
        this.configureClick = true;
        this.statusConfigEnable = true;
      }
    },
    //Configure button click event under Bins
    onConfigureClick() {
      this.projectClick = false;
      this.projectInvenBins = "";
      this.projectItemsInvenBins = [];
      this.projInvenBinsSelec = true;
      this.partNumberInventBins = "";
      this.dynamicSelected = true;
      this.businessInventBins = "";
      this.busiInvenBinsSelec = true;
      this.warehouseInventBins = "";
      this.wareInvenBinsSelec = true;
      this.locationInventBins = "";
      this.locInvenBinsSelec = true;
      this.OEMInventBins = "";
      this.OEMInvenBinsSelec = true;
      this.partTypeInventBins = "";
      this.partTypeInvenBinsSelec = true;
      this.partClassInventBins = "";
      this.partClassInvenBinsSelec = true;
      this.binTypeInventBins = "";
      this.binTypeInvenBinsSelec = true;
      this.isActiveStagingSkids = false;
      this.statusBins = "";
      this.statusConfigEnable = false;
      if (this.binSelected.length !== 0) {
        this.projectInvenBins = "";
        this.projectItemsInvenBins = [];
        this.projectDetailsInventoryBins();
        this.configureClick = false;
        this.statusConfigEnable = false;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any one of the Bins to configure.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Project dropdown under Inventory Bins
    async projectDetailsInventoryBins() {
      this.pass = 0;
      let obj = {
        pass: parseInt(this.pass),
        userid: this.userId,
        part_num: "",
        proj_key: 0,
        bu_key: 0,
        sbu_key: 0,
        ware_key: 0,
        loc: "",
      };
      let showMessage = false;
      let response = await APIUtility.drpDownProjectInvenBins(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.projectItemsInvenBins = response.Project;
        this.statusItemsBins = response.Result;
      }
    },
    //Specific Button click event under Inventory Bins
    onSpecificBtnClick() {
      this.specificDialog = true;
      this.partSearch();
      this.firstSearch = "";
      this.secondSearch = "";
      this.txtPartNumber = "";
      this.dynamicSelected = true;
      this.busiInvenBinsSelec = false;
      this.configureClick = true;
    },
    //Close button of v-dialog
    closeDialog() {
      this.specificDialog = false;
    },
    async partSearch() {
      let response = await APIUtility.partSearchDetails();
      if (response !== null && response !== undefined) {
        this.firstSearchItems = response.Dropdown1;
        this.secondSearchItems = response.Dropdown2;
      }
    },
    //Reset button under Part Search details
    onCancelPartSearchClick() {
      this.partSearch();
      this.firstSearch = "";
      this.secondSearch = "";
      this.txtPartNumber = "";
    },
    //Search button click event under Part Search
    async onPartSearchClick() {
      if (this.secondSearch == "BeginsWith") {
        this.searchPartNo = this.txtPartNumber + "%";
      } else if (this.secondSearch == "Contains") {
        this.searchPartNo = "%" + this.txtPartNumber + "%";
      }
      if (this.firstSearch == "part_num") {
        let obj = {
          sbu_key: 0,
          x_ware_key: 0,
          x_oem_key: 0,
          x_code: "",
          x_class_key: 0,
          x_btype_key: 0,
          part_num: this.searchPartNo,
          model: "",
          oem_model: "",
          part_desc: "",
          oem_part: "",
          proj_key: parseInt(this.projectInvenBins),
          kanban_control_bit: this.kanbanControlEnable,
        };
        let showMessage = false;
        let response = await APIUtility.searchPartDetails(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.totalRecords = response.length;
          this.partDetailsSearch = response;
          this.partDetailsShow = true;
        }
      } else if (this.firstSearch == "part_desc") {
        let obj = {
          sbu_key: 0,
          x_ware_key: 0,
          x_oem_key: 0,
          x_code: "",
          x_class_key: 0,
          x_btype_key: 0,
          part_num: "",
          model: "",
          oem_model: "",
          part_desc: this.searchPartNo,
          oem_part: "",
          proj_key: parseInt(this.projectInvenBins),
          kanban_control_bit: this.kanbanControlEnable,
        };
        let showMessage = false;
        let response = await APIUtility.searchPartDetails(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.totalRecords = response.length;
          this.partDetailsSearch = response;
          this.partDetailsShow = true;
        }
      } else if (this.firstSearch == "model") {
        let obj = {
          sbu_key: 0,
          x_ware_key: 0,
          x_oem_key: 0,
          x_code: "",
          x_class_key: 0,
          x_btype_key: 0,
          part_num: "",
          model: this.searchPartNo,
          oem_model: "",
          part_desc: "",
          oem_part: "",
          proj_key: parseInt(this.projectInvenBins),
          kanban_control_bit: this.kanbanControlEnable,
        };
        let showMessage = false;
        let response = await APIUtility.searchPartDetails(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.totalRecords = response.length;
          this.partDetailsSearch = response;
          this.partDetailsShow = true;
        }
      } else if (this.firstSearch == "oem_part") {
        let obj = {
          sbu_key: 0,
          x_ware_key: 0,
          x_oem_key: 0,
          x_code: "",
          x_class_key: 0,
          x_btype_key: 0,
          part_num: "",
          model: "",
          oem_model: "",
          part_desc: "",
          oem_part: this.searchPartNo,
          proj_key: parseInt(this.projectInvenBins),
          kanban_control_bit: this.kanbanControlEnable,
        };
        let showMessage = false;
        let response = await APIUtility.searchPartDetails(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.totalRecords = response.length;
          this.partDetailsSearch = response;
          this.partDetailsShow = true;
        }
      } else if (this.firstSearch == "oem_model") {
        let obj = {
          sbu_key: 0,
          x_ware_key: 0,
          x_oem_key: 0,
          x_code: "",
          x_class_key: 0,
          x_btype_key: 0,
          part_num: "",
          model: "",
          oem_model: this.searchPartNo,
          part_desc: "",
          oem_part: "",
          proj_key: parseInt(this.projectInvenBins),
          kanban_control_bit: this.kanbanControlEnable,
        };
        let showMessage = false;
        let response = await APIUtility.searchPartDetails(obj, showMessage);
        if (response !== null && response !== undefined) {
          this.totalRecords = response.length;
          this.partDetailsSearch = response;
          this.partDetailsShow = true;
        }
      }
    },
    //Part Selected
    selectedPart(item) {
      this.partNumberInventBins = item;
      this.partDetailsShow = false;
      this.specificDialog = false;
      this.partNumberInventBins = item.PartNo;
      this.projInvenBinsSelec = true;
      this.businessDetails();
    },
    //Dynamic button click event
    onDynamicBtnClick() {
      this.businessDetails();
      this.dynamicSelected = true;
      this.busiInvenBinsSelec = false;
      this.configureClick = true;
      this.projInvenBinsSelec = true;
    },
    //Business Data under Inventory Bins
    async businessDetails() {
      this.pass = 11;
      let obj = {
        pass: parseInt(this.pass),
        userid: 1,
        part_num: "",
        proj_key: parseInt(this.projectInvenBins),
        bu_key: 0,
        sbu_key: 0,
        ware_key: 0,
        loc: "",
      };
      let showMessage = false;
      let response = await APIUtility.drpDownProjectInvenBins(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.businessItemsInventBins = response.Result;
      }
    },
    //Business under Inventory Bins when selected
    busiInventBinsChange() {
      this.warehouseInventBins = "";
      this.warehouseItemsInventBins = [];
      this.locationInventBins = "";
      this.locationItemsInventBins = [];
      this.OEMInventBins = "";
      this.OEMItemsInventBins = [];
      this.partTypeInventBins = "";
      this.partTypeItemsInventBins = [];
      this.partClassInventBins = "";
      this.partClassItemsInventBins = [];
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];

      this.locInvenBinsSelec = true;
      this.OEMInvenBinsSelec = true;
      this.partTypeInvenBinsSelec = true;
      this.partClassInvenBinsSelec = true;
      this.binTypeInvenBinsSelec = true;
      this.warehouseItemsInventBins = this.businessItemsInventBins.filter((obj) => obj.bu_key === this.businessInventBins);
      this.wareInvenBinsSelec = false;
    },
    //Warehouse under Inventory Bins when selected
    wareInventBinsChange() {
      this.locationInventBins = "";
      this.locationItemsInventBins = [];
      this.OEMInventBins = "";
      this.OEMItemsInventBins = [];
      this.partTypeInventBins = "";
      this.partTypeItemsInventBins = [];
      this.partClassInventBins = "";
      this.partClassItemsInventBins = [];
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];
      this.OEMInvenBinsSelec = true;
      this.partTypeInvenBinsSelec = true;
      this.partClassInvenBinsSelec = true;
      this.binTypeInvenBinsSelec = true;
      this.locationItemsInventBins = this.warehouseItemsInventBins.filter((obj) => obj.ware_key === this.warehouseInventBins);
      this.locInvenBinsSelec = false;
    },
    //Location under Inventory Bins when selected
    async locInventBinsChange() {
      this.OEMInventBins = "";
      this.OEMItemsInventBins = [];
      this.partTypeInventBins = "";
      this.partTypeItemsInventBins = [];
      this.partClassInventBins = "";
      this.partClassItemsInventBins = [];
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];
      this.partTypeInvenBinsSelec = true;
      this.partClassInvenBinsSelec = true;
      this.binTypeInvenBinsSelec = true;
      this.pass = 2;
      let obj = {
        pass: parseInt(this.pass),
        userid: 1,
        part_num: "",
        proj_key: parseInt(this.projectInvenBins),
        bu_key: parseInt(this.businessInventBins),
        sbu_key: 0,
        ware_key: parseInt(this.warehouseInventBins),
        loc: this.locationInventBins,
      };
      let showMessage = false;
      let response = await APIUtility.drpDownProjectInvenBins(obj, showMessage);
      if (response !== null && response !== undefined) {
        this.OEMItemsInventBins = response.Result;
        this.OEMInvenBinsSelec = false;
      }
    },
    //OEM under Inventory Bins when selected
    OEMInventBinsChange() {
      this.partTypeInventBins = "";
      this.partTypeItemsInventBins = [];
      this.partClassInventBins = "";
      this.partClassItemsInventBins = [];
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];
      this.partClassInvenBinsSelec = true;
      this.binTypeInvenBinsSelec = true;
      this.partTypeItemsInventBins = this.OEMItemsInventBins.filter((obj) => obj.oem_key === this.OEMInventBins);
      this.partTypeInvenBinsSelec = false;
    },
    //Part Type under Inventory Bins when selected
    partTypeInventBinsChange() {
      this.partClassInventBins = "";
      this.partClassItemsInventBins = [];
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];
      this.binTypeInvenBinsSelec = true;
      this.partClassItemsInventBins = this.partTypeItemsInventBins.filter((obj) => obj.ptcode === this.partTypeInventBins);
      this.partClassInvenBinsSelec = false;
    },
    //Part Class under Inventory Bins when selected
    partClassInventBinsChange() {
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];
      this.binTypeItemsInventBins = this.partClassItemsInventBins.filter((obj) => obj.class_key === this.partClassInventBins);
      this.binTypeInvenBinsSelec = false;
    },
    //Bin Type when selected
    binTypeInventBinsChange() {
      this.binTypeInvenBinsSelec = false;
    },
    //Apply button click events
    //Apply under Inventory Bins
    onInventoryBinsApplyClick() {
      let result = this.binSelected?.map((a) => a.bin_id);
      this.binString = result.join(":") + ":";
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let onStagingSkidsApplyClickModel = {
        option: 2,
        binstring: this.binString,
        proj_key: parseInt(this.projectInvenBins),
        bu_key: parseInt(this.businessInventBins),
        sbu_key: 0,
        ware_key: parseInt(this.warehouseInventBins),
        loc: this.locationInventBins,
        oem_key: parseInt(this.OEMInventBins),
        ptcode: this.partTypeInventBins,
        class_key: parseInt(this.partClassInventBins),
        btype_key: parseInt(this.binTypeInventBins),
        part_num: this.partNumberInventBins,
      };
      this.axios
        .post("ut/bm_ut_binmanager_bin_config_update", onStagingSkidsApplyClickModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            //this.resetBins();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Apply under Status
    onStatusApplyClick() {
      let result = this.binSelected?.map((a) => a.bin_id);
      this.binString = result.join(":") + ":";
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let onStagingSkidsApplyClickModel = {
        option: 1,
        binstring: this.binString,
        proj_key: 0,
        bu_key: 0,
        sbu_key: 0,
        ware_key: 0,
        loc: "",
        oem_key: 0,
        ptcode: "",
        class_key: 0,
        btype_key: 0,
        part_num: "",
        code: this.statusBins,
      };
      this.axios
        .post("ut/bm_ut_binmanager_bin_config_update", onStagingSkidsApplyClickModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            // this.resetBins();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Apply under Staging Skids
    onStagingSkidsApplyClick() {
      let result = this.binSelected?.map((a) => a.bin_id);
      this.binString = result.join(":") + ":";
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let onStagingSkidsApplyClickModel = {
        option: 3,
        binstring: this.binString,
        proj_key: 0,
        bu_key: 0,
        sbu_key: 0,
        ware_key: 0,
        loc: "",
        oem_key: 0,
        ptcode: "",
        class_key: 0,
        btype_key: 0,
        part_num: "",
      };
      this.axios
        .post("ut/bm_ut_binmanager_bin_config_update", onStagingSkidsApplyClickModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            //this.resetBins();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Project change under Inventory Bins
    projectInventBinsChan() {
      this.projInvenBinsSelec = false;
      this.businessInventBins = "";
      this.businessItemsInventBins = [];
      this.warehouseInventBins = [];
      this.warehouseItemsInventBins = [];
      this.locationInventBins = "";
      this.locationItemsInventBins = [];
      this.OEMInventBins = "";
      this.OEMItemsInventBins = [];
      this.partTypeInventBins = "";
      this.partTypeItemsInventBins = [];
      this.partClassInventBins = "";
      this.partClassItemsInventBins = [];
      this.binTypeInventBins = "";
      this.binTypeItemsInventBins = [];

      this.busiInvenBinsSelec = true;
      this.locInvenBinsSelec = true;
      this.OEMInvenBinsSelec = true;
      this.partTypeInvenBinsSelec = true;
      this.partClassInvenBinsSelec = true;
      this.binTypeInvenBinsSelec = true;
      this.wareInvenBinsSelec = true;
    },
    deleteselect(item) {
      if (this.rowString === undefined) {
        this.rowString = item.Row + ":";
      } else {
        this.rowString += item.Row + ":";
      }
    },
    //Delete functionality
    onRowDeleteClick() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);

      this.axios
        .get("ut/bin_manager_delete?row_string=" + this.rowString + "&user_key=" + this.userId)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.DeleteRowSelected();
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.DeleteRowSelected();
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Reset button click event for the Search
    resetSearch() {
      this.projectItemsThirdSearch = [];
      this.rowItemsThirdSearch = [];
      this.searchBtnClickEvt = true;
      this.searchProjectSelected = true;
      this.binsSetFixedPart = false;
      this.discrepancyBins = false;
      this.undefinedBins = false;
      this.stagingSkids = false;
      this.kanbanCarts = false;
      this.rowDesignatorSearch = "";
      this.binRange1 = "";
      this.binRange2 = "";
      this.binStatus = "";
      this.binStatusItems = [];
      this.selectedProjSecSearch = "";
      this.projItemsSecSearch = [];
      this.selectedBusSecSearch = "";
      this.busiItemsSecSearch = [];
      this.selectedWareSecSearch = "";
      this.warehouseItemsSecSearch = [];
      this.locationSecSearch = "";
      this.locationItemsSecSearch = [];
      this.OEMSecSearch = "";
      this.OEMItemsSecSearch = [];
      this.partTypeSecSearch = "";
      this.partTypeItemsSecSearch = [];
      this.partClassSecSearch = "";
      this.partClassItemsSecSearch = [];
      this.binTypeSecSearch = "";
      this.binTypeItemsSecSearch = [];
      this.statusSecSearch = "";
      this.statusItemsSecSearch = [];
      this.selectedProject = true;
      this.selectedBusiness = true;
      this.selectedWarehouse = true;
      this.selectedLocation = true;
      this.selectedOEM = true;
      this.selectedPartType = true;
      this.selectedPartClass = true;
      this.selectedBinType = true;
      this.selectedStatus = true;
      this.binDetails = false;
      this.searchFirstItem = false;
      this.searchSecondItem = false;
      this.dropdownValuesSecondSearch();
      this.binStatusDataList();
      if (this.tab == 1) {
        this.$refs.firstSearchForm[0].resetValidation();
        this.$refs.secondSearchForm[0].resetValidation();
      }
    },
    //Reset button for Bins
    resetBins() {
      this.configureClick = false;
      this.projectInvenBins = "";
      this.projectItemsInvenBins = [];
      this.projInvenBinsSelec = true;
      this.partNumberInventBins = "";
      this.dynamicSelected = true;
      this.businessInventBins = "";
      this.busiInvenBinsSelec = true;
      this.warehouseInventBins = "";
      this.wareInvenBinsSelec = true;
      this.locationInventBins = "";
      this.locInvenBinsSelec = true;
      this.OEMInventBins = "";
      this.OEMInvenBinsSelec = true;
      this.partTypeInventBins = "";
      this.partTypeInvenBinsSelec = true;
      this.partClassInventBins = "";
      this.partClassInvenBinsSelec = true;
      this.binTypeInventBins = "";
      this.binTypeInvenBinsSelec = true;
      this.isActiveStagingSkids = false;
      this.statusBins = "";
      this.statusConfigEnable = false;
      this.projectClick = true;
      this.projectDetailsInventoryBins();
      if (this.binDetails) this.$refs.binInventoryBinsForm[0].resetValidation();
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
