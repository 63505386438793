import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  rowAddSubmitCall(obj, showMessage) {
    let call = "post";
    return this.getData("/ut/bm_ut_binmanager_row_add", call, obj, showMessage);
  },
  searchCall(obj, showMessage) {
    let call = "post";
    return this.getData("/ut/bm_ut_binmanager_load_grid", call, obj, showMessage);
  },
  drpDownValSecSearch() {
    let call = "get";
    return this.getData("/ut/bm_ut_binmanager_bin_config_prompt1", call);
  },
  searchForBtnClick(pass, userId) {
    let call = "get";
    return this.getData(`/ut/bm_ut_binmanager_bin_config_prompt0?Pass=${pass}&UserId=${userId}`, call);
  },
  searchForRowProject(pass, userId, project) {
    let call = "get";
    return this.getData(`/ut/bm_ut_binmanager_bin_config_prompt0?Pass=${pass}&UserId=${userId}&ProjectId=${project}`, call);
  },
  binStatusData() {
    let call = "get";
    return this.getData("/ut/bm_ut_binmanager_bin_config_bin_status", call);
  },
  drpDownProjectInvenBins(obj, showMessage) {
    let call = "post";
    return this.getData("/ut/bm_ut_binmanager_bin_config_prompt2", call, obj, showMessage);
  },
  searchPartDetails(obj, showMessage) {
    let call = "post";
    return this.getData("/ut/bm_ut_binmanager_bin_config_prompt3", call, obj, showMessage);
  },
  partSearchDetails() {
    let call = "get";
    return this.getData("/ut/bm_ut_binmanager_part_search", call);
  },
  getDeleteRow() {
    let call = "get";
    return this.getData("/ut/bm_ut_binmanager_row_delete", call);
  },
  deleteForRow(row_string, user_key) {
    let call = "get";
    return this.getData(`/ut/bin_manager_delete?row_string=${row_string}&user_key=${user_key}`, call);
  },
  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res != undefined) {
      if (res.data.body.message != undefined || res.data.body.message != null) {
        if (!res?.data?.body?.message) {
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return JSON.parse(res?.data?.body?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      } else {
        if (!res?.data?.message) {
          return {};
        }
        try {
          return JSON.parse(res?.data?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      }
    }
  },
};
